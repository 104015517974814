import React, { useState } from 'react';

import isEmpty from 'lodash/isEmpty';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { INFLUENCER_HUB_EMAIL_ENDPOINT } from 'constants/apis';
import { resendVerificationEmail } from 'reducers/outreach/outreach-integrations';
import { performPost } from 'services/rest-service/rest-service';

import outreachMessages from '../../../../../components/outreach/OutreachNotConnected.messages';

import EmailCampaignComposerAddVerifiedEmailModal from './EmailCampaignComposerAddVerifiedEmailModal';

type HTMLProps = Omit<React.HTMLAttributes<HTMLElement>, 'className'>;

interface ConnectedEmailCampaignComposerAddVerifiedEmailModalProps
  extends HTMLProps {
  isOpen: boolean;
  toggle: () => void;
  hasMockDataDevFF: boolean;
  checkVerifiedOrIntegratedEmail: (arg0: string) => void;
  verifiedOrIntegratedEmailValidation: boolean;
  notVerifiedEmail: boolean;
  resetVerifiedOrIntegratedEmailValidation: (arrg: boolean) => void;
}

const ConnectedEmailCampaignComposerAddVerifiedEmailModal: React.FC<ConnectedEmailCampaignComposerAddVerifiedEmailModalProps> = ({
  isOpen,
  toggle,
  hasMockDataDevFF,
  checkVerifiedOrIntegratedEmail,
  verifiedOrIntegratedEmailValidation,
  notVerifiedEmail,
  resetVerifiedOrIntegratedEmailValidation,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [blockedValidation, setBlockedValiditation] = useState(false);

  const handleOnClose = () => {
    toggle();
  };

  const handleResendVerificationEmail = async integration => {
    const verificationUrl = `${window.location.protocol}//${window.location.host}/#/email-verification`;
    const supportUrl = 'mailto:support@cision.com';

    await dispatch(
      resendVerificationEmail({
        email: integration.emailAddress,
        senderName: integration.senderName,
        verificationUrl,
        supportUrl,
        intl,
        messages: outreachMessages,
      }),
    );
  };

  const checkBlockedEmail = async emailAddress => {
    const payload = {
      emails: [emailAddress],
    };

    const result = await performPost(
      `${INFLUENCER_HUB_EMAIL_ENDPOINT}/blocked`,
      payload,
    );

    if (!isEmpty(result.data.blocked)) {
      setBlockedValiditation(true);
    } else {
      setBlockedValiditation(false);
    }
  };

  return (
    <EmailCampaignComposerAddVerifiedEmailModal
      isOpen={isOpen}
      onClose={handleOnClose}
      onSendVerificationEmail={handleResendVerificationEmail}
      hasMockDataDevFF={hasMockDataDevFF}
      checkBlockedEmail={checkBlockedEmail}
      blockedValidation={blockedValidation}
      checkVerifiedOrIntegratedEmail={checkVerifiedOrIntegratedEmail}
      verifiedOrIntegratedEmailValidation={verifiedOrIntegratedEmailValidation}
      notVerifiedEmail={notVerifiedEmail}
      resetVerifiedOrIntegratedEmailValidation={
        resetVerifiedOrIntegratedEmailValidation
      }
    />
  );
};

export default ConnectedEmailCampaignComposerAddVerifiedEmailModal;
