import axios from 'axios';
import { produce } from 'immer';

import {
  LANGUAGES_ENDPOINT,
  PHRASES_ENDPOINT,
  TRANSLATION_DESCRIPTORS_ENDPOINT,
  TRANSLATION_ENDPOINT,
} from 'constants/apis';
import {
  performGet,
  performPut,
  performDelete,
  performPost,
} from 'services/rest-service/rest-service';

import { ROOT_LANGUAGE_ID, ROOT_REGION_ID } from './constants';

import { PhraseCreateModel } from './types/api/PhraseCreateModel';
import { PhraseTranslation } from './types/api/PhraseTranslation';
import { PhraseWithTranslations } from './types/api/PhraseWithTranslations';
import { PhraseViewModel } from './types/PhraseViewModel';
import { TranslationState } from './types/TranslationState';
import { buildEditorViewModel } from './utils/buildEditorViewModels';
import {
  findRootTranslation,
  findRootTranslationOnViewModel,
} from './utils/findRootTranslation';
import { defaultPhraseViewModelComparer } from './utils/itemComparers';

declare global {
  interface Window {
    PAGE_DATA: {
      uiEndPoint: string;
      version: string;
      frontEndVersionBundleDir: string;
    };
  }
}

// Actions
export const LOAD_LANGUAGES = 'translations/LOAD_LANGUAGES';
export const LOAD_LANGUAGES_SUCCESS = 'translations/LOAD_LANGUAGES_SUCCESS';
export const LOAD_LANGUAGES_ERROR = 'translations/LOAD_LANGUAGES_ERROR';
export const LOAD_DESCRIPTORS = 'translations/LOAD_DESCRIPTORS';
export const LOAD_DESCRIPTORS_SUCCESS = 'translations/LOAD_DESCRIPTORS_SUCCESS';
export const LOAD_DESCRIPTORS_ERROR = 'translations/LOAD_DESCRIPTORS_ERROR';
export const LOAD_PHRASES = 'translations/LOAD_PHRASES';
export const LOAD_PHRASES_SUCCESS = 'translations/LOAD_PHRASES_SUCCESS';
export const LOAD_PHRASES_ERROR = 'translations/LOAD_PHRASES_ERROR';
export const ERROR_UNAUTHORIZED = 'translations/ERROR_UNAUTHORIZED';
export const PHRASE_CHANGED = 'translations/PHRASE_CHANGED';

// Constant values
const DEFAULT_PHRASE_CATEGORY = 'MISSING_CATEGORY';

type GetStateDelegate = () => { translation: TranslationState };
type DispatchDelegate = (action: any) => void;
export type TranslationThunkActionCreator = (
  ...args
) => (dispatch: DispatchDelegate, getState: GetStateDelegate) => Promise<any>;

// Dispatchers
export const loadLanguages: TranslationThunkActionCreator = () => async (
  dispatch,
  getState,
) => {
  const { languagesLoading, languagesLoaded } = getState().translation;
  if (languagesLoading || languagesLoaded) {
    return;
  }

  const promise = performGet(LANGUAGES_ENDPOINT);
  dispatch({ type: LOAD_LANGUAGES, promise });

  try {
    const result = await promise;
    dispatch({
      type: LOAD_LANGUAGES_SUCCESS,
      payload: { languages: result.data },
    });
  } catch (e) {
    dispatch({ type: LOAD_LANGUAGES_ERROR });
  }
};

export const loadDescriptors: TranslationThunkActionCreator = () => async (
  dispatch,
  getState,
) => {
  // hack to ensure we can call CDN based resources without setting any of our own custom headers
  const basicGet = (url: string) =>
    axios({
      method: 'GET',
      url,
    });

  const { descriptorsLoading, descriptorsLoaded } = getState().translation;
  if (descriptorsLoading || descriptorsLoaded) {
    return;
  }

  const angularTranslationsEditorDefs = `${window.PAGE_DATA.uiEndPoint}${window.PAGE_DATA.version}/output/default-message-descriptors.json`;
  const reactTranslationsEditorDefs = `${window.PAGE_DATA.frontEndVersionBundleDir}translations-editor-definitions.json`;
  //TODO: add types for MessageDescriptor and return promise of array of those
  const promise = Promise.all([
    basicGet(angularTranslationsEditorDefs),
    basicGet(reactTranslationsEditorDefs),
    performGet(TRANSLATION_DESCRIPTORS_ENDPOINT),
  ]);
  dispatch({ type: LOAD_DESCRIPTORS, promise });

  try {
    const responses = await promise;
    const result = responses.reduce(
      (prev, current) => prev.concat(current.data),
      [],
    );
    dispatch({
      type: LOAD_DESCRIPTORS_SUCCESS,
      payload: { descriptors: result },
    });
  } catch (e) {
    dispatch({ type: LOAD_DESCRIPTORS_ERROR });
  }
};

export const loadPhrases: TranslationThunkActionCreator = () => async (
  dispatch,
  getState,
) => {
  dispatch(loadLanguages());
  dispatch(loadDescriptors());

  // track existing Promises so we don't trigger an extra request
  const {
    languagesLoadingPromise,
    descriptorsLoadingPromise,
    phrasesLoadingPromise,
  } = getState().translation;

  // already waiting for phrases so no need to do anything more
  if (phrasesLoadingPromise) {
    return;
  }

  const phraseLoadingPromise = descriptorsLoadingPromise?.then(() => {
    const { descriptors } = getState().translation;

    const phraseLoadingPromise = new Promise<PhraseWithTranslations[]>(
      (resolve, reject) => {
        const models: PhraseCreateModel[] = descriptors.map(el => {
          return {
            phraseKey: el.id,
            categoryName: el.category || DEFAULT_PHRASE_CATEGORY,
            description: el.description,
          };
        });

        performPut(PHRASES_ENDPOINT, { phrases: models })
          .then((response: { data: unknown }) => {
            const serverPhrases = response.data as PhraseWithTranslations[];
            resolve(serverPhrases);
          })
          .catch(err => {
            reject(err);
          });
      },
    );
    return phraseLoadingPromise;
  });

  dispatch({ type: LOAD_PHRASES, promise: phraseLoadingPromise });

  const getLookupKey = (p: { phraseKey: string; categoryName: string }) =>
    p.phraseKey + p.categoryName;

  Promise.all([phraseLoadingPromise, languagesLoadingPromise])
    .then(([serverPhrasesResponse]) => {
      const { descriptors, languages } = getState().translation;
      const serverPhrases = serverPhrasesResponse as PhraseWithTranslations[];

      const serverPhrasesDictionary: {
        [key: string]: PhraseWithTranslations;
      } = serverPhrases.reduce((hashMap, p) => {
        const lookupKey = getLookupKey(p);
        hashMap[lookupKey] = p;
        return hashMap;
      }, {});

      const viewModels: PhraseViewModel[] = [];
      for (const d of descriptors) {
        const lookupKey = getLookupKey({
          phraseKey: d.id,
          categoryName: d.category,
        });
        const serverPhrase = serverPhrasesDictionary[lookupKey];
        // We are done with this phrase, so delete it from our lookup.
        // This matters because everything that is left on the object
        // after we have iterated the descriptors are handled after this part
        delete serverPhrasesDictionary[lookupKey];
        const rootTranslation: PhraseTranslation = {
          phraseTranslationId: null,
          languageId: ROOT_LANGUAGE_ID,
          regionId: ROOT_REGION_ID,
          text: d.defaultMessage ?? '',
        };

        // We do not allow server to override the root translation if we have been
        // given a descriptor for it. (no idea why, just inheriting the logic)
        const serverTranslations = serverPhrase.phraseTranslations.filter(
          t => t.languageId !== ROOT_LANGUAGE_ID,
        );

        const phrase: PhraseWithTranslations = {
          phraseKey: d.id,
          phraseId: serverPhrase?.phraseId,
          categoryName: d.category ?? DEFAULT_PHRASE_CATEGORY,
          description: d.description,
          phraseTranslations: [rootTranslation, ...serverTranslations],
        };

        const viewModel = buildEditorViewModel(phrase, languages);

        viewModels.push(viewModel);
      }

      /* Now deal with all the phrases we got from the server, but for which
       * we lack a descriptor.
       * Basically this means all systems where the root translation is managed
       * by adding keys to the DB instead of having a file we can download somewhere.
       * At the time of coding this the only 2 apps using the descriptors are
       * trendkite-front-end and the WAG Angular app.
       * I.e. all server side translations will be using the logic below.
       */
      const orphaned: PhraseWithTranslations[] = [];
      // iterate keys, since we delete the properties matching descriptors above
      for (const k of Object.keys(serverPhrasesDictionary)) {
        const phrase = serverPhrasesDictionary[k];

        const root = findRootTranslation(phrase);
        /* The logic that was decided on before our time is that if
         * a translation comes from the server and have no root entry,
         * it is considered an orphaned phrase no longer in use.
         */
        if (!root) {
          orphaned.push(phrase);
          continue;
        }

        // Server has provided the root level translation, so just treat
        // this as a regular phrase.
        const viewModel = buildEditorViewModel(phrase, languages);
        viewModels.push(viewModel);
      }

      // eslint-disable-next-line no-console
      console.info(
        `TEST Filtered out ${orphaned.length} orphaned phrase(s)`,
        orphaned,
        '\nkeys:',
        orphaned.map(p => `${p.categoryName}/${p.phraseKey}`),
      );

      viewModels.sort(defaultPhraseViewModelComparer);

      dispatch({
        type: LOAD_PHRASES_SUCCESS,
        payload: { phrases: viewModels },
      });
    })
    .catch(() => {
      dispatch({ type: LOAD_PHRASES_ERROR });
    });
};

export function phraseChangedActionCreator(phrase: PhraseViewModel) {
  return {
    type: PHRASE_CHANGED,
    phrase,
  };
}

export const deletePhraseTranslation = (
  phraseId: number,
  translationId: number,
) => async (dispatch: DispatchDelegate, getState: GetStateDelegate) => {
  const state = getState().translation;
  const originalPhrase = state.phrases.find(p => p.phraseId === phraseId);
  if (!originalPhrase)
    throw new Error('Can only create translations for phrases that exist.');
  const pendingPhrase = produce(originalPhrase, draft => {
    const translation = draft.translations.find(
      t => t.phraseTranslationId === translationId,
    );
    if (!translation)
      throw new Error(
        `Can't find translation ${translationId} on phrase ${phraseId}`,
      );
    const root = findRootTranslationOnViewModel(draft);

    // reset state to original
    translation.text = root?.text ?? '';
    translation.isInherited = true; // since we just removed the value we will by definition inherit
    translation.pendingApiCall = 'deleting';
  });

  dispatch(phraseChangedActionCreator(pendingPhrase));

  performDelete(
    `${TRANSLATION_ENDPOINT}/phrase-translations?id=${translationId}`,
  )
    .then(() => {
      const commitedPhrase = produce(pendingPhrase, draft => {
        const t = draft.translations.find(
          t => t.phraseTranslationId === translationId,
        );
        if (!t)
          throw new Error(
            `Can't find translation ${translationId} on phrase ${phraseId}`,
          );

        // remove ID so next call to server becomes a create
        t.phraseTranslationId = null;
        t.pendingApiCall = undefined;
      });

      dispatch(phraseChangedActionCreator(commitedPhrase));
    })
    .catch(() => {
      // fallback, revert to original state. this could theoretically be an issue if we
      // have a new call occuring before this one returns but meh
      if (originalPhrase)
        // to make tsc happy
        dispatch(phraseChangedActionCreator(originalPhrase));
    });
};

export const updatePhraseTranslation = (
  phraseId: number,
  phraseTranslationId: number,
  text: string,
  // for some godforsaken reason the server validates this despite
  // not ever using it for update since we provide the phraseTranskationId
  languageId: number,
) => async (dispatch: DispatchDelegate, getState: GetStateDelegate) => {
  const state = getState().translation;
  const originalPhrase = state.phrases.find(p => p.phraseId === phraseId);
  if (!originalPhrase)
    throw new Error('Can only update translations for phrases that exist.');

  const findTranslation = (p: PhraseViewModel) =>
    p.translations.find(pt => pt.phraseTranslationId === phraseTranslationId);
  const pendingPhrase = produce(originalPhrase, draft => {
    const translation = findTranslation(draft);
    if (!translation) {
      throw new Error(
        `Translation with id ${phraseTranslationId} does not exist on phrase with id ${phraseId}`,
      );
    }
    translation.text = text;
    translation.pendingApiCall = 'updating';
  });

  dispatch(phraseChangedActionCreator(pendingPhrase));

  performPut(`${TRANSLATION_ENDPOINT}/phrase-translations`, {
    phraseTranslationId,
    text,
    languageId,
  })
    .then(() => {
      const commitedPhrase = produce(pendingPhrase, draft => {
        const t = findTranslation(draft);
        if (t) {
          t.pendingApiCall = undefined;
        }
      });

      dispatch(phraseChangedActionCreator(commitedPhrase));
    })
    .catch(() => {
      dispatch(phraseChangedActionCreator(originalPhrase));
    });
};

export const createPhraseTranslation = (
  phraseId: number,
  text: string,
  languageId: number,
  regionId: number | null,
) => async (dispatch: DispatchDelegate, getState: GetStateDelegate) => {
  const state = getState().translation;

  const language = state.languages.find(l => l.languageId === languageId);
  if (!language)
    throw new Error('Can only add translation for an existing language.');

  const originalPhrase = state.phrases.find(p => p.phraseId === phraseId);
  if (!originalPhrase)
    throw new Error('Can only create translations for phrases that exist.');

  const findTranslation = (p: PhraseViewModel) =>
    p.translations.find(
      t => t.languageId === languageId && t.regionId === regionId,
    );
  const pendingPhrase = produce(originalPhrase, draft => {
    const translation = findTranslation(draft);
    if (!translation)
      throw new Error(
        `Failed to find translation for language ${languageId} region ${regionId}`,
      );

    // doubt we can get called with this state, but lets make sure the models look like expected
    if (translation.phraseTranslationId)
      throw new Error(
        `Translation for language ${languageId} region ${regionId} already exist. Update the existing one instead.`,
      );

    translation.text = text;
    translation.isInherited = false; // Obviously not since we're creating a translation
    translation.pendingApiCall = 'creating';
  });

  // Immediately display updated value
  dispatch(phraseChangedActionCreator(pendingPhrase));

  performPost(`${TRANSLATION_ENDPOINT}/phrase-translations`, {
    phraseId,
    languageId,
    regionId,
    text,
  })
    .then((resp: { data: { phraseTranslationId: number } }) => {
      const commitedPhrase = produce(pendingPhrase, draft => {
        const t = findTranslation(draft);
        if (t) {
          t.phraseTranslationId = resp.data.phraseTranslationId;
          t.pendingApiCall = undefined;
        }
      });
      const action = phraseChangedActionCreator(commitedPhrase);
      dispatch(action);
    })
    .catch(() => {
      // fallback, revert to original state
      dispatch(phraseChangedActionCreator(originalPhrase));
    });
};

function replacePhrase(state: TranslationState, newPhrase: PhraseViewModel) {
  // We can't actually delete entire phrases, so no need to handle that
  return produce(state, draft => {
    const existingIndex = state.phrases.findIndex(
      p => p.phraseId === newPhrase.phraseId,
    );
    if (existingIndex >= 0) {
      draft.phrases.splice(existingIndex, 1, newPhrase);
    } else {
      draft.phrases.unshift(newPhrase);
    }
  });
}

const initialState: TranslationState = {
  languages: [],
  languagesLoaded: false,
  languagesLoading: false,
  descriptors: [],
  descriptorsLoaded: false,
  descriptorsLoading: false,
  phrases: [],
  phrasesLoaded: false,
  phrasesLoading: false,
};

export default (state = initialState, action): TranslationState => {
  switch (action.type) {
    case LOAD_LANGUAGES:
      return {
        ...state,
        languagesLoading: true,
        languagesLoadingPromise: action.promise,
      };
    case LOAD_LANGUAGES_SUCCESS:
      return {
        ...state,
        languagesLoading: false,
        languagesLoaded: true,
        languages: action.payload.languages,
      };
    case LOAD_LANGUAGES_ERROR:
      return {
        ...state,
        languagesLoading: false,
      };
    case LOAD_DESCRIPTORS:
      return {
        ...state,
        descriptorsLoading: true,
        descriptorsLoadingPromise: action.promise,
      };
    case LOAD_DESCRIPTORS_ERROR:
      return {
        ...state,
        descriptorsLoading: false,
      };
    case LOAD_DESCRIPTORS_SUCCESS:
      return {
        ...state,
        descriptorsLoading: false,
        descriptorsLoaded: true,
        descriptors: action.payload.descriptors,
      };
    case LOAD_PHRASES:
      return {
        ...state,
        phrasesLoading: true,
        phrasesLoadingPromise: action.promise,
      };
    case LOAD_PHRASES_ERROR:
      return {
        ...state,
        phrasesLoading: false,
        phrases: [],
      };
    case LOAD_PHRASES_SUCCESS:
      return {
        ...state,
        phrasesLoading: false,
        phrasesLoaded: true,
        phrases: action.payload.phrases,
      };
    case PHRASE_CHANGED:
      return produce(state, draft => replacePhrase(draft, action.phrase));
    default:
      return state;
  }
};
