import React, { useState, ChangeEvent } from 'react';

import { Tooltip } from '@cision/rover-ui';
import { useIntl } from 'react-intl';

import {
  Button as TkUiButton,
  Modal,
  Input,
  Type,
  SvgIcon,
} from '@trendkite/ui';

import globalMessages from 'i18n/Global.messages';

import TranslatedMessage from 'i18n/TranslatedMessage';

import { userHasDevFeatureFlag } from 'services/feature-service/feature-service';

import {
  EMAIL_SECURITY_LINK,
  DEV_FEATURES,
} from '../../../../../../../app/src/constants/constants';
import { isValidEmail } from '../../../../../../../tk-ui/src/validators';

import messages from './EmailCampaignComposerAddVerifiedEmailModal.messages';
import styles from './EmailCampaignComposerAddVerifiedEmailModal.module.css';
type HTMLProps = Omit<React.HTMLAttributes<HTMLElement>, 'className'>;

interface EmailCampaignComposerAddVerifiedEmailModalProps extends HTMLProps {
  isOpen: boolean;
  onClose: () => void;
  onSendVerificationEmail: (emailAddress) => void;
  hasMockDataDevFF: boolean;
  checkBlockedEmail: (emailAddress) => void;
  blockedValidation: boolean;
  checkVerifiedOrIntegratedEmail: (arg0: string) => void;
  verifiedOrIntegratedEmailValidation: boolean;
  notVerifiedEmail: boolean;
  resetVerifiedOrIntegratedEmailValidation: (arrg: boolean) => void;
}

const EmailCampaignComposerAddVerifiedEmailModal: React.FC<EmailCampaignComposerAddVerifiedEmailModalProps> = ({
  isOpen = false,
  onClose,
  onSendVerificationEmail,
  hasMockDataDevFF,
  checkBlockedEmail,
  blockedValidation,
  checkVerifiedOrIntegratedEmail,
  verifiedOrIntegratedEmailValidation,
  notVerifiedEmail,
  resetVerifiedOrIntegratedEmailValidation,
}) => {
  const intl = useIntl();

  const [emailAddress, setEmail] = useState('');
  const [senderName, setSenderName] = useState('');
  const [validEmail, setValidEmail] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>): void => {
    let emailValue = event.target.value;
    emailValue = emailValue.replace(/\s+/g, '');

    checkVerifiedOrIntegratedEmail(emailValue);
    if (isValidEmail(emailValue)) {
      setValidEmail(true);
      if (emailValue) {
        checkBlockedEmail(emailValue);
      }
    }
    setEmail(emailValue);
  };

  const handleSendVerificationEmail = () => {
    if (isValidEmail(emailAddress)) {
      onSendVerificationEmail({
        emailAddress: emailAddress,
        senderName: senderName,
      });
      setConfirmationModalOpen(true);
    }
  };

  const handleModalClose = () => {
    // Reset the email validation states
    resetVerifiedOrIntegratedEmailValidation(false);
    setValidEmail(false);
    setEmail('');
    setSenderName('');
    setConfirmationModalOpen(false);
    onClose();
  };

  const hasDkimStatusFlag = userHasDevFeatureFlag(
    DEV_FEATURES.enableDkimSignedStatus,
  );

  const handleOnSenderNameChange = (
    event: ChangeEvent<HTMLInputElement>,
  ): void => {
    const senderName = event.target.value;
    setSenderName(senderName);
  };
  return (
    <Modal
      toggle={handleModalClose}
      data-qa="smxjFD4M8IQvB67uHjJQ-"
      isOpen={isOpen}
      size={hasDkimStatusFlag ? 'lg' : 'md'}
    >
      <Modal.Header toggle={onClose}>
        {hasDkimStatusFlag ? (
          <TranslatedMessage {...messages.verifyYourEmail} />
        ) : (
          <TranslatedMessage {...messages.register} />
        )}
      </Modal.Header>
      {!confirmationModalOpen ? (
        <Modal.Body>
          <div
            style={{
              marginLeft: hasDkimStatusFlag ? '18px' : '0',
              marginTop: hasDkimStatusFlag ? '-16px' : '0',
              ...(blockedValidation && { marginBottom: '-4%' }),
            }}
          >
            <p className={styles.required}>
              <Type className={styles.modalInputLabel}>
                {hasDkimStatusFlag ? (
                  <TranslatedMessage
                    {...messages.verifyYourEmailDescription}
                    values={{
                      LINE_BREAK: <br />,
                      // eslint-disable-next-line react/display-name
                      b: (chunks: string) => (
                        <a
                          className={styles.emailSecurityLink}
                          href={EMAIL_SECURITY_LINK}
                          target="blank"
                        >
                          {chunks}
                        </a>
                      ),
                    }}
                  />
                ) : (
                  <TranslatedMessage {...messages.pleaseEnterEmail} />
                )}
              </Type>
            </p>
            <Input
              data-qa="xYpP_IakZ1IPYrdeLYNtj"
              type="input"
              placeholder={intl.formatMessage(
                messages.placeHolderVerificationEmail,
              )}
              style={
                hasDkimStatusFlag
                  ? { marginTop: '-8px', paddingLeft: '8px' }
                  : {}
              }
              value={emailAddress}
              onChange={handleOnChange}
            />
            {blockedValidation ? (
              <p className={styles.blockedEmailText}>
                <TranslatedMessage
                  {...messages.blockedEmailVerificationEmail}
                />
              </p>
            ) : null}
            {verifiedOrIntegratedEmailValidation && (
              <p className={styles.alreadyVerifiedOrIntegratedEmailText}>
                <TranslatedMessage
                  {...messages.alreadyVerifiedOrIntegratedEmail}
                />
              </p>
            )}
            {notVerifiedEmail && (
              <p className={styles.alreadyVerifiedOrIntegratedEmailText}>
                <TranslatedMessage {...messages.notVerifiedEmail} />
              </p>
            )}
            <div className="t-mt-4">
              <div className={styles.senderNameField}>
                <label htmlFor="sender_name">
                  {intl.formatMessage(messages.modalInputSenderNameLabel)}
                </label>{' '}
                <Tooltip
                  direction="bottom"
                  content={intl.formatMessage(
                    messages.modalInputSenderNameTooltip,
                  )}
                  className="t-ml-1"
                  showOnHover
                >
                  <SvgIcon icon="info-question" width={16} height={16} />
                </Tooltip>
              </div>
              <Input
                type="input"
                id="sender_name"
                placeholder={intl.formatMessage(
                  messages.modalInputSenderNamePlaceholder,
                )}
                value={senderName}
                onChange={handleOnSenderNameChange}
              />
            </div>
          </div>
        </Modal.Body>
      ) : (
        <Modal.Body>
          <TranslatedMessage
            {...messages.sentEmailVerification}
            values={{ EMAIL_ADDRESS: emailAddress }}
          />
        </Modal.Body>
      )}
      <Modal.Footer>
        {!confirmationModalOpen ? (
          <Modal.Actions>
            <Modal.Action>
              <TkUiButton
                data-qa="DJE7o_-Jg_rBD2ymR_nHx"
                modifiers={['round', 'tertiary', 'justify-right']}
                onClick={onClose}
              >
                <TranslatedMessage {...globalMessages.cancel} />
              </TkUiButton>
            </Modal.Action>
            <Modal.Action>
              <TkUiButton
                data-qa="geOVxALogVw6KqJhjHte8"
                disabled={
                  !emailAddress ||
                  !isValidEmail(emailAddress) ||
                  !validEmail ||
                  hasMockDataDevFF ||
                  blockedValidation ||
                  verifiedOrIntegratedEmailValidation ||
                  notVerifiedEmail
                }
                modifiers={['round', 'primary', 'justify-right']}
                onClick={handleSendVerificationEmail}
              >
                <TranslatedMessage {...messages.sendVerificationEmail} />
              </TkUiButton>
            </Modal.Action>
          </Modal.Actions>
        ) : (
          <Modal.Actions>
            <Modal.Action>
              <TkUiButton
                data-qa="DsLuLqUZCC_aJs4vef55R"
                modifiers={['round', 'primary', 'justify-right']}
                onClick={() => {
                  onClose();
                  setConfirmationModalOpen(false);
                  resetVerifiedOrIntegratedEmailValidation(false);
                }}
              >
                <TranslatedMessage {...messages.closeVerificationEmail} />
              </TkUiButton>
            </Modal.Action>
          </Modal.Actions>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default EmailCampaignComposerAddVerifiedEmailModal;
