import { FEATURES, DEV_FEATURES } from 'constants/constants';
import {
  userHasDevFeatureFlag,
  userHasFeatureFlag,
  userHasSomeFeatureFlags,
} from 'services/feature-service/feature-service';

const MEDIA_TYPES_VALIDATIONS = {
  canAccessOnlineNews: () => true,
  canAccessBlogs: () => true,
  canAccessBroadcastData: () => userHasFeatureFlag(FEATURES.broadcastData),
  canAccessPrintData: () =>
    userHasSomeFeatureFlags([FEATURES.printDataUk, FEATURES.printData]),
  canAccessNewsEdgeData: () => userHasFeatureFlag(FEATURES.newsedge),
  canAccessFactivaData: () => userHasFeatureFlag(FEATURES.factivaContentDJ),
  canAccessManuallyAddedData: () =>
    userHasDevFeatureFlag(DEV_FEATURES.userAddedArticleFilter),
  canAccessPodcastData: () => userHasFeatureFlag(FEATURES.podcasts),
};

export default MEDIA_TYPES_VALIDATIONS;
